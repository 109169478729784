import type { Page } from "~/types";
import { SectionName } from "~/types";

interface Section {
  strapiComponentName: SectionName;
  componentName: string;
  data: unknown;
}

const componentMapping: Map<string, string> = new Map([
  ["sections.headline-strapi-card-group", "StrapiCardList"],
  ["sections.card-list", "StrapiCardList"],
  ["sections.headline-and-link-group", "WraplinkList"],
  ["sections.cta-banner", "Cta"],
  ["sections.explore-products-by-topic", "PostCardList"],
  [
    "sections.explore-products-by-topic-two-column-list",
    "PostCardTwoColumnList",
  ],
  [
    "sections.explore-products-by-topic-video-slider",
    "VideoTestimonialsSlider",
  ],
  ["sections.explore-products-by-topic-slider", "PostCardSlider"],
  ["sections.testimonial-list", "TestimonialsList"],
  ["sections.testimonials-video", "VideoTestimonialsCustomSlider"],
  ["sections.explore-by-tags", "PostCardShuffleList"],
  ["sections.icon-text-list", "IconText"],
]);

export function getDynamicPostSections(post: unknown) {
  const sections: Section[] = [];

  for (const section of post.sections) {
    const buildSection: Section = {
      strapiComponentName: section.__component as SectionName,
      componentName: transformComponentName(section.__component, section),
      data: section,
    };

    sections.push(buildSection);
  }

  return { sections } ?? {};
}

export function getDynamicSections<P extends Page>(page?: P) {
  const sections: Section[] = [];
  let topSection: Section = <Section>{};

  for (const section of page?.attributes?.sections) {
    const buildSection: Section = {
      strapiComponentName: section.__component as SectionName,
      componentName: transformComponentName(section.__component, section),
      data: section,
    };

    if (
      (section.__component === "sections.headline-main" &&
        section.image?.data) ||
      section.__component === "sections.home-page-hero"
    ) {
      topSection = {
        strapiComponentName: section.__component as SectionName,
        componentName: transformComponentName(section.__component),
        data: section,
      };
    } else {
      sections.push(buildSection);
    }
  }

  return { sections, topSection } ?? {};
}

function transformComponentName(
  strapiComponentName: string,
  sectionObject?: Section
): string {
  /**
   * Transforms the given Strapi component name to a corresponding component name.
   *
   * @param strapiComponentName - The Strapi component name to transform.
   * @param sectionObject - The section object associated with the component (optional).
   * @returns The transformed component name.
   */

  if (!strapiComponentName) {
    return "";
  }

  if (
    strapiComponentName === "sections.explore-products-by-topic" &&
    sectionObject?.layout === "Slider"
  ) {
    strapiComponentName = `${strapiComponentName}-${
      sectionObject.collection === "video-testimonials" ? "video-" : ""
    }slider`;
  }

  if (
    strapiComponentName === "sections.explore-products-by-topic" &&
    sectionObject?.layout === "Two Columns List"
  ) {
    strapiComponentName = `${strapiComponentName}-two-column-list`;
  }

  const transformedName = componentMapping.get(strapiComponentName) || "";
  if (transformedName) {
    return transformedName;
  }

  const [, componentName] = strapiComponentName.split(".");
  return componentName
    .split("-")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join("");
}
